@tailwind base;
@tailwind components;
@tailwind utilities;



.bg-gradient-primary {
    background-image: linear-gradient(150deg, #2E56A7 0%, #BB6DE5 100%) !important;
}


h1 {
    /* font-family: 'Golos Text' !important; */
    /* font-weight: 700 !important; */
    /* letter-spacing: -3.5px; */
    /* line-height: 100% !important; */
}
  
.text-gradient-primary {
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    text-fill-color: transparent !important;
    background-image: linear-gradient(100deg, #2E56A7 0%, #BB6DE5 100%) !important;
}

.form-group-color-input label {
    margin-left: 33px !important;
}
.form-group-color-input input {
    padding-left: 45px !important;
}

@media (min-width: 768px) {
    .aside:not(.open):not(:hover) .brand-logo .logo-text-part {
        display: none !important;
    }
}

.show {
    visibility: visible !important;
}

.btn-close {
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat !important;
}

.svg-icon.breadcrumb-icon {
    display: inline-block !important;
}

.btn {
    background-color: var(--bs-btn-bg) !important;
}
.btn:hover {
    background-color: var(--bs-btn-hover-bg) !important;
    border-color: var(--bs-btn-hover-border-color) !important;
    color: var(--bs-btn-hover-color) !important;
}


.aside:not(.open):not(:hover) .subscription-widget-nav {
    display: none !important;
}

body {
    @apply !h-full !h-[100svh];
}

html, body, div {
    /* scroll-behavior: smooth !important; */
}